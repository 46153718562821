import React from "react";

import './Home.css'

function Home (props) {
    return (
        <div id='home'>
            <h1>Home</h1>
        </div>
    )
}

export default Home