import React from "react";

import './Footer.css'

function Footer (props) {
    return (
        <div id='footer'>
            <h1>Footer</h1>
        </div>
    )
}

export default Footer