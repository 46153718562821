import React from "react";

import Header from './header/Header';
import Body from './body/Body';
import Footer from './footer/Footer';

const App = () => (
  <div>
    <Header/>
    <Body/>
    <Footer/>
  </div>
);

export default App;
