import React from "react";

import './Users.css'

function Users (props) {
    return (
        <div id='users'>
            <h1>Users</h1>
        </div>
    )
}

export default Users